import React, { useEffect, useState } from "react";
import "./Akadime.scss";
import { ApiGet } from "../../../helpers/API/ApiData";
import { NavLink } from "react-router-dom";
import Loader from "../../../helpers/loader";

const Akadime = () => {
  const [VideoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getVideoData = async () => {
    setLoading(true);
    try {
      let resp = await ApiGet("video/getVideo");
      setVideoData(resp?.data?.payload?.data);
    } catch (error) {
      console.error("error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVideoData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="akadime-page-alignment">
        <h2>Willkommen in der WePro Akademie</h2>
        <div className="text-style">
          <p>
            Die WePro Akademie ist deine zentrale Wissensplattform für eine
            erfolgreiche Tätigkeit als Handelsvertreter der WePro Deutschland
            GmbH. Hier findest du alle wichtigen Schulungsvideos, die du für
            deine eigenverantwortliche Arbeit im Vertrieb brauchst.
          </p>
          <p>
            Von grundlegenden Informationen bis hin zu vertiefenden Inhalten die
            Akademie hilft dir, dein Wissen zu erweitern, deine Fähigkeiten zu
            stärken und deinen Erfolg nachhaltig zu sichern.
          </p>
        </div>
        <div className="three-col-grid">
          {VideoData?.map((item, index) => (
            <NavLink to={`/videoPage?id=${item?._id}`}>
              <div key={index} className="three-col-grid-items">
                <h3>{item?.mainTitle}</h3>
                <div className="image-box">
                  <img src={item?.image} />
                  <div className="icon-alignment">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 21 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 0C4.70138 0 0 4.92525 0 11C0 17.0747 4.70138 22 10.5 22C16.2986 22 21 17.0747 21 11C21 4.92525 16.2986 0 10.5 0ZM10.5 2.55127C14.9537 2.55127 18.5647 6.33418 18.5647 11C18.5647 15.6658 14.9537 19.4487 10.5 19.4487C6.04627 19.4487 2.4353 15.6658 2.4353 11C2.4353 6.33418 6.04627 2.55127 10.5 2.55127ZM7.875 5.50805V16.4919L15.75 11L7.875 5.50805Z"
                        fill="#3D3D3D"
                      />
                    </svg>
                  </div>
                </div>
                <div className="details-alignment">
                  <h4>{item?.subTitle}</h4>
                  <p>{item?.description}</p>
                  <button>
                    {" "}
                    <NavLink to={`/videoPage?id=${item?._id}`}>Start</NavLink>
                  </button>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};

export default Akadime;
