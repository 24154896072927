import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import "./dockument.scss";
import { ApiGet } from "../../../helpers/API/ApiData";
const Dokumente = () => {
  const [leadData, setLeadData] = useState();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const getDocumentsData = async () => {
    setLoading(true);
    try {
      let response = await ApiGet(`documents/getDocument`);
      console.log(response?.data?.payload, "response?.data?.payload");

      console.log("leadData", leadData);
      setCount(response?.data?.payload?.count);
      setLeadData(response?.data?.payload?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocumentsData();
  }, []);

  const groupedData =
    Array.isArray(leadData) &&
    leadData?.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Get the Blob from the response
      const blob = await response.blob();

      // Force correct MIME type for .docx files
      const mimeType = fileUrl.endsWith(".docx")
        ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        : blob.type;

      const correctedBlob = new Blob([blob], { type: mimeType });

      // Ensure the correct file name and extension
      const extension = fileUrl.split(".").pop().split("?")[0];
      const defaultName = fileName || `downloaded_file.${extension}`;

      // Create an anchor element for downloading
      const a = document.createElement("a");
      const url = window.URL.createObjectURL(correctedBlob);

      a.href = url;
      a.download = defaultName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the Object URL to free up memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      {loading ? (
        <TailSpin color="#FFF" height={25} width={25} />
      ) : (
        <div className="neueleads-container">
          <div className="neulead-title">
            <p>Dokumente</p>
            <span></span>
          </div>
          <div className="newleads-datatable">
            <div className="newleads-datatable-alignment">
              <table className="newleads-datatable-table">
                <thead>
                  <tr>
                    <th>Kategorie</th>
                    <th>Thema</th>
                    <th>DOWNLOAD</th>
                  </tr>
                </thead>
                <tbody>
                  {groupedData &&
                    Object.entries(groupedData).map(
                      ([category, documents], categoryIndex) => (
                        <React.Fragment key={category}>
                          <tr>
                            <th className="category"> {category}</th>
                          </tr>
                          {documents.map((doc, docIndex) => (
                            <tr key={doc._id}>
                              <td>{docIndex + 1}</td>
                              <td>
                                <p>{doc.name}</p>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDownload(doc.PDFUrl, doc.name)
                                  }
                                >
                                  Download
                                </button>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      )
                    )}
                </tbody>
              </table>
            </div>
            {/* <div className="text-area-table-main">
              <div className="text-area-table">
                <div className="text-area">
                  <label>Team Dokument</label>
                  <textarea
                    placeholder="write text"
                    name="description"
                    value={docDesctiption?.description}
                    disabled={!isInputDisable}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                </div>
                <div className="edit-button">
                  {!isInputDisable ? (
                    <button
                      type="button"
                      onClick={() => setIsInputDisable(true)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M-0.0078125 13.2069V15.4998C-0.0078125 15.6325 0.044866 15.7596 0.138634 15.8534C0.232402 15.9472 0.359579 15.9998 0.492188 15.9998H2.79019C2.92254 15.9998 3.04948 15.9473 3.14319 15.8538L12.5912 6.40585L9.59119 3.40585L0.139188 12.8538C0.0453521 12.9474 -0.00751912 13.0744 -0.0078125 13.2069ZM10.8292 2.16685L13.8292 5.16685L15.2892 3.70685C15.4767 3.51932 15.582 3.26501 15.582 2.99985C15.582 2.73468 15.4767 2.48038 15.2892 2.29285L13.7042 0.706849C13.5167 0.519378 13.2624 0.414062 12.9972 0.414062C12.732 0.414062 12.4777 0.519378 12.2902 0.706849L10.8292 2.16685Z"
                          fill="black"
                        ></path>
                      </svg>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="save-button"
                      onClick={() => handleSubmitDescription()}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
export default Dokumente;
